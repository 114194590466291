/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const v = 'is-visible';

	const slide = () => {
		if($('.index-slide01').length) {
			$('.index-slide01').slick({
				autoplay: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></a>',
				nextArrow: '<a class="slick-next" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></a>',
				appendArrows: $('.index-slide01-arrowArea'),
				dots: true,
				centerMode: true,
				infinite: true,
				variableWidth: true,
				loop: true,
				responsive: [{
					breakpoint: 1024,
					settings: {
						variableWidth: false,
						centerPadding: '8%',
						appendArrows: $('.index-slide01'),
						dots: false,
					}
				}]
			});
		}
		if($('.index-slide02').length) {
			$('.index-slide02').slick({
				autoplay: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></a>',
				nextArrow: '<a class="slick-next" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></a>',
				dots: false,
				infinite: true,
				loop: true,
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
					}
				}]
			});
		}
	};
	const tabChange = () => {
		if($('.index-tab-content').length) {
			const btn = $('.index-tab-btn');
			const content = $('.index-tab-content');
			const select = $('.index-tab-select');
			const more = $('.index-tab-more');
			btn.eq(0).addClass(a);
			content.eq(0).addClass(a);
			select.val(0);
			btn.each(function(){
				$(this).on('click', function(){
					let index = btn.index(this);
					btn.removeClass(a);
					$(this).addClass(a);
					content.removeClass(a);
					content.eq(index).addClass(a);
					select.val(index);
					if($('.index-tab-content .__title').length) {
						$('.index-tab-content .__title').matchHeight();
					}
				});
			});
			select.change(function(){
				btn.removeClass(a);
				content.removeClass(a);
				btn.eq($(this).val()).addClass(a);
				content.eq($(this).val()).addClass(a);
				if($('.index-tab-content .__title').length) {
					$('.index-tab-content .__title').matchHeight();
				}
			});
			more.each(function(){
				if($(this).parents('.index-tab-content').find('li').length > 8) {
					$(this).addClass(v);
				}
				$(this).on('click', function(){
					$(this).parents('.index-tab-content').find('ul').addClass(v);
					$(this).removeClass(v);
				});
			});
		}
	};

	const initSeminars = () => {
		const selector = '#vue-seminars';
		const elem = $(selector);
		const apiPath = elem.data('path');


		$.ajax({url: apiPath}).then(resp => {
			let data = {};
			data.itemList = resp;

			// console.log(resp);

			new Vue({
				el: selector,
				data() {
					return data;
				}
			});
		});
	};

	slide();
	tabChange();
	initSeminars();

})(window.jQuery, window.FUNCTIONS);
$(window).on('load', function(){
	if($('.index-slide02').length) {
		var imgHeight = $('.index-slide02-item img').eq(0).height();
		var arrowHeight = $('.index-slide02 .slick-arrow').eq(0).height();
		var arrowPosition = (imgHeight / 2) - (arrowHeight / 2);
		$('.index-slide02 .slick-arrow').css('top', arrowPosition);
	}
});
